import { React, useState } from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';

const jokes = [
  {
    setup: "Why do programmers prefer dark mode?",
    punchline: "Because light attracts bugs."
  },
  {
    setup: "Why do hackers prefer dark mode?",
    punchline: "Because they like to stay in the shadows."
  },
  {
    setup: "Why did the hacker quit his job?",
    punchline: "He didn't get arrays."
  }
];

const quotes = [
  {
    content: "It's not a bug, it's a feature.",
    author: "Unknown"
  },
  {
    content: "Any sufficiently advanced technology is indistinguishable from magic.",
    author: "Arthur C. Clarke"
  },
  {
    content: "Programs must be written for people to read, and only incidentally for machines to execute.",
    author: "Harold Abelson"
  }
];

const TerminalController = (props = {}) => {
  const [terminalLineData, setTerminalLineData] = useState([
    <TerminalOutput>Welcome to the Hacker House!</TerminalOutput>
  ]);

  const handleCommand = async (input) => {
    const command = input.split(' ')[0];
    const args = input.split(' ').slice(1);

    switch (command) {
      case 'help':
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>
            Available commands: <br />
            - hack [website]: Attempt to hack a website <br />
            - news: Get the latest news about hacking <br />
            - joke: Get a random hacker joke <br />
            - quote: Get a random hacker quote <br />
          </TerminalOutput>,
        ]);
        break;
      case 'hack':
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>
            Hacking {args[0]}... <br />
            Access granted. Data stolen: 20GB.
          </TerminalOutput>,
        ]);
        break;
      case 'news':
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>Latest news about hacking: No news is good news.</TerminalOutput>,
        ]);
        break;
      case 'joke':
        const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>
            {randomJoke.setup} <br />
            {randomJoke.punchline}
          </TerminalOutput>,
        ]);
        break;
      case 'quote':
        const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>
            {randomQuote.content} <br />
            - {randomQuote.author}
          </TerminalOutput>,
        ]);
        break;
      default:
        setTerminalLineData((prevState) => [
          ...prevState,
          <TerminalOutput>Command not found. Type 'help' for a list of available commands.</TerminalOutput>,
        ]);
        break;
    }
  };

  return (
    <div className="container">
      <Terminal name='Hacker House' colorMode={ ColorMode.Dark }  onInput={ handleCommand }>
        { terminalLineData }
      </Terminal>
    </div>
  )
};

export default TerminalController;